import { useEffect } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserRegTextField } from "../../../../components/TextField";
import { getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import pxToRem from "../../../../helpers/pxToRem";
import useTextInput from "../../../../hooks/useTextInput";
import { RootState } from "../../../../redux/store";
import { UserUpdate, updateFields } from "../../../../redux/userRegistrationSlice";
import styles from "../index.module.scss";
import { RegModalWrapper } from "../ModalContent";
import { getIncompleteState } from "../../ReviewPage/helper";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { updateUserInfo } from "../../../../firebase/general-apis";
import { isNoChange } from "./helper";

const Names = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);
    const userId = useSelector((state: RootState) => state.user.userId);

    const reg = useSelector((state: RootState) => state.userReg.fields);
    const step = useSelector((state: RootState) => state.userReg.step);
    const firstName = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "First Name") }, defaultValue: reg.firstName?.value || user?.firstName || "" });
    const lastName = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Last Name") }, defaultValue: reg.lastName?.value || user?.lastName || "" });
    const preferredName = useTextInput({ inputValidator: (input: string) => { return undefined }, defaultValue: reg.preferredName?.value || user?.preferredName || "" });

    const isAllComplete = () => {
        return !firstName.hasError && !lastName.hasError && !preferredName.hasError
    }

    // const noChange = () => isNoChange(
    //     {firstName: firstName.value, lastName: lastName.value, preferredName: preferredName.value},
    //     {firstName: reg.firstName?.value, lastName: reg.lastName?.value, preferredName: reg.preferredName?.value}
    // )

    const handleUpdate = async () => {
        try {
            firstName.setHasTouched(true);
            lastName.setHasTouched(true);
            preferredName.setHasTouched(true);

            // if(noChange()) return;
            if (!user || !userId) throw new Error("User is not authenticated.")

            let updateData: UserUpdate = {}
            updateData = {
                user: {
                    path: '/',
                    data: {
                        ...(!firstName.hasError && firstName.value !== user.firstName ? { firstNameActual: firstName.value } : null),
                        ...(!lastName.hasError && lastName.value !== user.lastName ? { lastName: lastName.value } : null),
                        // ...(preferredName.value !== user.preferredName ? {firstNameActual: preferredName.value?preferredName.value:null} : null),
                        // ...(preferredName.value !== user.preferredName ?  preferredName.value ? {firstNameActual: preferredName.value} : {firstNameActual: firstName.value } : null),
                        ...(preferredName.value ? { firstName: preferredName.value } : { firstName: firstName.value }),
                    }
                },
                teacher: {
                    path: '/',
                    data: {
                        ...(!firstName.hasError && firstName.value !== reg.firstName?.value ? { firstNameActual: { value: firstName.value, state: 'submitted' } } : null),
                        ...(!lastName.hasError && lastName.value !== reg.lastName?.value ? { lastName: { value: lastName.value, state: 'submitted' } } : null),
                        // ...(preferredName.value !== user.preferredName ?  reg.preferredName?.value ? {firstNameActual: {value: preferredName.value, state: 'submitted'}} : {firstNameActual: {value: firstName.value, state: 'submitted'}} : null),
                        ...(preferredName.value.trim() !== '' ? { firstName: { value: preferredName.value, state: 'submitted' } } : { firstName: { value: firstName.value, state: 'submitted' } }),
                    }
                }
            }
            await updateUserInfo(userId, updateData);
            // dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message))
        }
    };

    useEffect(() => {
        reg.firstName?.value ? firstName.setValue(reg.firstName?.value) : user?.firstName ? firstName.setValue(user.firstName) : firstName.reset();
        reg.lastName?.value ? lastName.setValue(reg.lastName?.value) : user?.lastName ? lastName.setValue(user.lastName) : lastName.reset();
        reg.preferredName?.value ? preferredName.setValue(reg.preferredName?.value) : user?.preferredName ? preferredName.setValue(user.preferredName) : preferredName.reset();
        // reg.firstName?.value ? firstName.setValue(reg.firstName?.value):firstName.reset();
        // reg.lastName?.value ? lastName.setValue(reg.lastName?.value):lastName.reset();
        // reg.preferredName?.value ? preferredName.setValue(reg.preferredName?.value):preferredName.reset();
    }, [reg.firstName, user?.firstName, user?.lastName, user?.preferredName, reg.lastName, reg.preferredName])

    return (
        <RegModalWrapper title="Basic Details" handleUpdate={handleUpdate}
            state={getIncompleteState({
                firstName: reg.firstName,
                lastName: reg.lastName,
            })} isAllComplete={isAllComplete()}
        >
            <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                <Stack className={styles.titleContainer}>
                    <h5 className={styles.h5}>Names</h5>
                    <p className={styles.p}>Your first and last name must be the names that are displayed on your identity documents and your teaching accreditation.</p>
                </Stack>
                <Stack className={styles.fieldContainer}>
                    <Stack direction={'row'} width='100%' gap={pxToRem(24)}>
                        <UserRegTextField
                            value={firstName.value}
                            onChange={(e) => firstName.setValue(e.target.value)}
                            error={firstName.hasTouched && firstName.hasError} helperText={firstName.getErrorMessage()}
                            label="First Name*" sx={{ flexGrow: 1 }}
                        />
                        <UserRegTextField
                            value={lastName.value}
                            onChange={(e) => lastName.setValue(e.target.value)}
                            error={lastName.hasTouched && lastName.hasError} helperText={lastName.getErrorMessage()}
                            label="Last Name*" sx={{ flexGrow: 1 }}
                        />
                    </Stack>
                    <UserRegTextField
                        value={preferredName.value}
                        onChange={(e) => {
                            preferredName.setValue(e.target.value)
                        }}
                        error={preferredName.hasTouched && preferredName.hasError} helperText={preferredName.getErrorMessage()}
                        label="Preferred Name" />
                </Stack>
            </Stack>
        </RegModalWrapper>
    )
}

export default Names;