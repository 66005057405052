import { useDispatch, useSelector } from "react-redux";
import { EmployeeDocsModalWrapper, RegModalWrapper } from "../ModalContent";
import { RootState } from "../../../../redux/store";
import pxToRem from "../../../../helpers/pxToRem";
import { Stack } from "@mui/material";
import styles from "../index.module.scss";
import { useEffect, useState } from "react";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import { FieldState, TeacherAccountState } from "../../../../firebase/types-teacher";
import { LightTooltip } from "../../../../components/Tooltip";
import { getEmpDocs, handleEmpDocsChange } from "./helper";
import { MultiFileType } from "../../../../components/FileUpload";
import { v4 as uuidv4 } from 'uuid';
import { setErrorMsg } from "../../../../redux/uiSlice";
import FileUpload from "../../../../components/FileUpload";
import { RegMultipleFiles } from "../../../../components/FileUpload/RegUploadComponents";
import { updateUserInfo } from "../../../../firebase/general-apis";


const TaxDetails = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);
    const accountState = useSelector((state: RootState) => state.userReg.fields.accountState?.value);

    const [files, setFiles] = useState<MultiFileType[]>(getEmpDocs(reg.tax?.docs));
    const onFileChange = (file: File) => {
        setFiles(prev => [...prev, { id: uuidv4(), file: file, fileName: file.name }]);
    }
    const handleFileRemove = (id: string) => {
        setFiles(prev => prev.filter(el => el.id !== id));
    }

    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            let updateData: UserUpdate = {};

            const data = await handleEmpDocsChange(userId, reg.tax?.docs, files)
            updateData = {
                teacher: {
                    path: '/tax',
                    data: {
                        ...data,
                        state: files.length > 0 ? FieldState.SUBMITTED : null
                    }
                }
            }
            await updateUserInfo(userId, updateData);
            // dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    useEffect(() => {
        setFiles(getEmpDocs(reg.tax?.docs));
    }, [reg.tax?.docs])

    return (
        <>

            {
                accountState !== TeacherAccountState.INCOMPLETE.valueOf() && reg.tax?.state === 'incomplete' ?
                    <EmployeeDocsModalWrapper title={"Employee Documents - Tax File Number"} handleUpdate={handleUpdate}>
                        <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                            <Stack className={styles.titleContainer}>
                                <h5 className={styles.h5} >Tax File Number</h5>
                                {
                                    reg.tax.state === FieldState.INCOMPLETE ?
                                        <Stack className={styles.infoBox}>
                                            <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            <small>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br /><br />Please download the Federal Government standard form template below, complete it and upload it.</small>
                                        </Stack>
                                        :
                                        <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                            <LightTooltip placement="bottom-start" title={<p className={styles.p}>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br /><br />Please download the Federal Government standard form template below, complete it and upload it.</p>}>
                                                <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            </LightTooltip>
                                            <p className={styles.p}>Why we need this information</p>
                                        </Stack>
                                }
                            </Stack>
                            <a href="https://firebasestorage.googleapis.com/v0/b/spark-a968d.appspot.com/o/0000000000000-PUBLIC%2Fstandard-forms%2FTax%20File%20Number%20Declaration%20Form%20-%20Spark%20Relief%20Teachers.pdf?alt=media&token=d2bc4fae-39f9-4db3-8daa-772edc935e7b"
                                target="_blank" style={{ textDecoration: 'none', width: 'fit-content' }}>
                                <small className={styles.link}>Download blank form</small>
                            </a>
                            <FileUpload onChangeCallback={onFileChange} multiple extensions={'document'} iconColor={styles.primaryYellow500} label={<small className={styles.fileUploadLabel}>Upload Document</small>}/>
                            <RegMultipleFiles files={files} removeFile={handleFileRemove} />
                        </Stack>
                    </EmployeeDocsModalWrapper>
                    :
                    <RegModalWrapper title="Employee Documents - Tax File Number" handleUpdate={handleUpdate}
                        state={getIncompleteState({
                            tax: reg.tax,
                        })} isAllComplete={files.length > 0}
                    >
                        <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                            <Stack className={styles.titleContainer}>
                                <h5 className={styles.h5} >Tax File Number</h5>
                                {
                                    reg.tax?.state === FieldState.INCOMPLETE ?
                                        <Stack className={styles.infoBox}>
                                            <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            <small>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br /><br />Please download the Federal Government standard form template below, complete it and upload it.</small>
                                        </Stack>
                                        :
                                        <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                            <LightTooltip placement="bottom-start" title={<p className={styles.p}>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br /><br />Please download the Federal Government standard form template below, complete it and upload it.</p>}>
                                                <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8" /> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white" /> </svg>
                                            </LightTooltip>
                                            <p className={styles.p}>Why we need this information</p>
                                        </Stack>
                                }
                            </Stack>
                            <a href="https://firebasestorage.googleapis.com/v0/b/spark-a968d.appspot.com/o/0000000000000-PUBLIC%2Fstandard-forms%2FTax%20File%20Number%20Declaration%20Form%20-%20Spark%20Relief%20Teachers.pdf?alt=media&token=d2bc4fae-39f9-4db3-8daa-772edc935e7b"
                                target="_blank" style={{ textDecoration: 'none', width: 'fit-content' }}>
                                <small className={styles.link}>Download blank form</small>
                            </a>
                            <FileUpload onChangeCallback={onFileChange} multiple extensions={'document'} iconColor={styles.primaryYellow500} label={<small className={styles.fileUploadLabel}>Upload Document</small>}/>
                            <RegMultipleFiles files={files} removeFile={handleFileRemove} />
                        </Stack>
                    </RegModalWrapper>
            }
        </>
    )
};

export default TaxDetails;