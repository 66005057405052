import moment from "moment";
import { TeacherFields } from "../redux/types";

export const mapValue = ( value: number, fromLow: number, fromHigh: number, toLow: number, toHigh: number ): number => Math.round( value < fromLow ? toLow : value > fromHigh ? toHigh : ((value - fromLow) * (toHigh - toLow)) / (fromHigh - fromLow) + toLow );
export const getError = (error: any):string => { if (error instanceof Error) return error.message; else return error; }


export const getInitials = (text: string):string => { 
    let temp = text;
    let newText = '';
    temp.split('').map((el, i) => {
        if(el === '(' && i+1 < text.length && text.charAt(i) !== ' ') {
            newText = newText + ' ' + el + ' ';
        } else if (el === ')' && i-1 > 0 && text.charAt(i-1) !== ' ') {
            newText = newText + ' ' + el;
        } else {
            newText = newText + el;
        }
    })
    let r = newText.split(" ").map((n)=>n[0]).join(''); 
    return r;
}

export const allFieldsDefined = (obj?: Object, exceptKeys?: string[]): boolean => {
    if(obj === undefined) {return false;}
    return Object.entries(obj).every(([k,v]) => {
        if(exceptKeys !== undefined && exceptKeys.includes(k)) {
            return true;
        }
        return v !== undefined
    });
}

export const someFieldsDefined = (obj?: Object, exceptKeys?: string[]): boolean => {
    if(obj === undefined) {return false;}
    return Object.entries(obj).some(([k,v]) => {
        if(exceptKeys !== undefined && exceptKeys.includes(k)) {
            return true;
        }
        return v !== undefined
    });
}

export const getFullname = (fields: TeacherFields) => {
    return `${fields.preferredName?.value || fields.firstName?.value} ${fields.lastName?.value}`;
}

export const isDocExpired = (docDate?: string) => {
    if(!docDate) return false;
    return moment(docDate, "DD-MM-YYYY").isBefore(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"));
}