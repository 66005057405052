import { useEffect, useMemo, useState } from "react";
import { PrimaryButton } from "../../../components/Button";
import pxToRem from "../../../helpers/pxToRem";
import { Modal, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setFromEdit, setOpenRegModal, setProfilePhotoStep, setStateList, setStep, updateFields } from "../../../redux/userRegistrationSlice";
import { getModalGroup } from "./ModalContent";
import { isObjectEmpty, updateUserInfo } from "../../../firebase/general-apis";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { TabNames, DashboardTabNames } from "../../../routers/type";

interface IRegistrationModals {
    buttonText?: string;
    withButton?: boolean;
}

export const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: pxToRem(1280),
    height: '90%',
    bgcolor: 'background.paper',
    backgroundColor: 'white',
    boxShadow: '0px 4px 4px 0px #00000040',
    borderRadius: pxToRem(16),
}

const RegistrationModals = ({buttonText, withButton}: IRegistrationModals) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = useSelector((state: RootState) => state.userReg.openRegModal);
    const userId = useSelector((state: RootState) => state.user.userId);
    const accountState = useSelector((state: RootState) => state.userReg.fields.accountState?.value);
    const step = useSelector((state: RootState) => state.userReg.step);
    const fromEdit = useSelector((state: RootState) => state.userReg.fromEdit);
    const profilePhotoStep = useSelector((state: RootState) => state.userReg.profilePhotoStep);
    const user = useSelector((state: RootState) => state.user.user);
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const schoolTeachingDocuments = useSelector((state: RootState) => state.userReg.stateTeachingDocuments);
    const eccTeachingDocuments = useSelector((state: RootState) => state.userReg.stateECCDocuments);
    const locationState = useSelector((state: RootState) => state.userReg.locationState);
    const employmentSetting = useSelector((state: RootState) => state.userReg.employmentSetting);

    const [loading, setLoading] = useState(true);

    const teachingDocuments = useMemo(() => {
        return {
            school: schoolTeachingDocuments ? schoolTeachingDocuments[`${locationState}`]||[] : null,
            earlyChildcare: eccTeachingDocuments ? eccTeachingDocuments[`${locationState}`]||[]: null,
        }
    }, [schoolTeachingDocuments, eccTeachingDocuments, locationState])

    const [child, setChild] = useState<JSX.Element>();

    const updateData = useSelector((state: RootState) => state.userReg.userUpdate);

    const modalList = useMemo(() => {
        if(!reg || !teachingDocuments || !employmentSetting) return [];
        return getModalGroup(reg, teachingDocuments, employmentSetting)
    }, [reg, teachingDocuments, employmentSetting]);

    // const listOfItems = useMemo(() => {
    //     if(!teachingDocuments || !locationState) {return [];}
    //     switch(accountState) {
    //         case TeacherAccountState.REJECTED: case TeacherAccountState.ONHOLD:
    //             return modalList;
    //         default:
    //             return modalList;
    //     }
    // }, [reg, accountState, teachingDocuments, locationState])

    useEffect(() => {
        dispatch(setStateList(modalList.map(el=>el.state)));
        // if(modalList!==undefined) {
        // } else {
        //     dispatch(setStateList([]));
        // }
    }, [modalList])

    useEffect(() => {
        // if(!modalList) return;
        if(step >= 0 && step < modalList.length) {
            setChild(modalList[step].modal);
        }
        else if(modalList.length > 0) {
            dispatch(setOpenRegModal(false));
            dispatch(setStep(0));
            navigate(`/${TabNames.DASHBOARD}/${DashboardTabNames.REVIEW}`)
        }
    }, [step, modalList])

    const update = async () => {
        if(!userId || updateData === undefined) return;
        await updateUserInfo(userId, updateData);
        dispatch(updateFields(undefined));
    }

    // useEffect(() => {
    //     update();
    // },[updateData])

    // When 
    useEffect(() => {
        setLoading(true);
        if(!open) {
            modalList.length > 0 && setChild(modalList[0].modal);
            dispatch(setStep(0));
            dispatch(setFromEdit(undefined));
            return;
        }
        if(fromEdit !== undefined) {
            // dispatch(setStep(listOfItems.findIndex(el => el.id === fromEdit)));
            if(!locationState) {return;}
            setChild(modalList.find(el => el.id === fromEdit)?.modal);
            let index = modalList.findIndex(el => el.id === fromEdit);
            if(index>=0) {
                dispatch(setStep(index));
            }
        } else if(profilePhotoStep !== undefined) {
            // dispatch(setStep(listOfItems.findIndex(el => el.id === 'profilePhoto')));
            // setChild(listOfItems.find(el => el.id === 'profilePhoto')?.modal);
            let index = modalList.findIndex(el => el.id === 'profilePhoto');
            if(index > -1) {
                dispatch(setStep(index));
            }
            dispatch(setProfilePhotoStep(undefined));
        } else if(reg.accountState?.firstTime === undefined || reg.accountState?.firstTime === true) {
            dispatch(setStep(0));
        } 

        // On modal close
        
        setLoading(false);
    }, [open])

    return (
        <>
            {withButton && <PrimaryButton loading={user===undefined || isObjectEmpty(reg)} sx={{width: '80%', fontSize: pxToRem(16), minHeight: pxToRem(50)}} onClick={()=>{navigate(`/${TabNames.DASHBOARD}/${DashboardTabNames.MY_ACCOUNT}`);}}>{buttonText || "Click here"}</PrimaryButton>}
            <Modal open={open}>
                <Stack sx={modalStyle}>
                    {
                        isObjectEmpty(reg) || loading ? 
                            <LoadingSpinner/>
                        :
                        <>
                            {child}
                        </>
                    }
                </Stack>
            </Modal>
        </>
    );
};

export default RegistrationModals;