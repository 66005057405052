import { Container, Divider, Fade, IconButton, Stack, useMediaQuery, Zoom } from "@mui/material";
import pxToRem from "../../../helpers/pxToRem";
import { AccreditationDocumentDetails, LocalTeacherEmploymentSetting, REG_STEP, TeacherFields } from "../../../redux/types";
import styles from "./index.module.scss";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { PrimaryOutlinedButton, PrimaryButton } from "../../../components/Button";
import { setOpenRegModal, setStep } from "../../../redux/userRegistrationSlice";
import { accredDocParam, getIncompleteState } from "../ReviewPage/helper";
import Contact from "./components/Contact";
import EmergencyContact from "./components/EmergencyContact";
import Names from "./components/Names";
import PhotoHoldingID from "./components/PhotoHoldingID";
import PrimaryIdentity from "./components/PrimaryIdentity";
import ProfilePhoto from "./components/ProfilePhoto";
import Reference from "./components/Reference";
import SecondaryIdentity from "./components/SecondaryIdentity";
import WorkingRights from "./components/WorkingRights";
import { FieldState, TeacherAccountState } from "../../../firebase/types-teacher";
import TeachingDocumentation from "./components/TeachingDocumentation";
import TeacherRegStepper from "../../../components/Stepper";
import AccreditationLevel from "./components/AccreditationLevel";
import { getVerifiedAccreditationDoc } from "../helper";
import BankDetails from "./components/BankDetails";
import SuperDetails from "./components/SuperDetails";
import TaxDetails from "./components/TaxDetails";


export const getModalGroup = (fields: TeacherFields, docs: accredDocParam, setting: LocalTeacherEmploymentSetting) => {
    const namesState = getIncompleteState({ firstName: fields.firstName, lastName: fields.lastName });
    const contactState = getIncompleteState({ email: fields.email, contact: fields.contact, homeAddress: fields.homeAddress })
    const profilePhotoState = getIncompleteState({ profilePicture: fields.profilePicture });
    const workingRightsState = getIncompleteState({ workingRights: fields.workingRights })
    const primaryIdentityState = getIncompleteState({ primaryIdentification: fields.primaryIdentification })
    const secondaryIdentityState = getIncompleteState({ secondaryIdentification: fields.secondaryIdentification })
    const photoHoldingIDState = getIncompleteState({ holdingIdPhotoURL: fields.holdingIdPhotoURL })
    const referenceState = getIncompleteState({ reference: fields.reference, })
    const emergencyState = getIncompleteState({ firstEmergencyContact: fields.firstEmergencyContact, secondEmergencyContact: fields.secondEmergencyContact, })
    const bankState = getIncompleteState({ bank: fields.bank })
    const superState = getIncompleteState({ super: fields.super })
    const taxState = getIncompleteState({ tax: fields.tax });

    const accredLevelState = fields.service && Object.keys(fields.service).length > 0 ? FieldState.SUBMITTED : FieldState.INCOMPLETE;

    const temp = [
        { id: REG_STEP.NAMES, modal: <Names />, state: namesState },
        { id: REG_STEP.CONTACT, modal: <Contact />, state: contactState },
        { id: REG_STEP.PROFILE_PHOTO, modal: <ProfilePhoto />, state: profilePhotoState },
        { id: REG_STEP.WORKING_RIGHTS, modal: <WorkingRights />, state: workingRightsState },
        { id: REG_STEP.PRIMARY_DOC, modal: <PrimaryIdentity />, state: primaryIdentityState },
        { id: REG_STEP.SECONDARY_DOC, modal: <SecondaryIdentity />, state: secondaryIdentityState },
        { id: REG_STEP.HOLDING_ID, modal: <PhotoHoldingID />, state: photoHoldingIDState },
        ...(
            ['school', 'both'].includes(setting.type) && docs.school !== null ?
                (docs.school as AccreditationDocumentDetails[]).map(el => {
                    return {
                        id: el.type,
                        modal: <TeachingDocumentation key={el.type} title={`Teaching Documentation - ${el.short_title}`} details={el} employmentSetting={'school'} />,
                        // state: fields.accreditation?.school?.[`${locationState}`]?.[`${el.type}`]?.state || FieldState.INCOMPLETE
                        state: getVerifiedAccreditationDoc(fields.accreditation?.school?.[`${setting.locationState}`], el.type)?.state || FieldState.INCOMPLETE
                    }
                }) : []
        ),
        ...(
            ['earlyChildcare', 'both'].includes(setting.type) && docs.earlyChildcare !== null ?
                // Add ECC Accreditation Level condition here
                // getECCDocsByLevel(docs.earlyChildcare, setting).map(el => {
                docs.earlyChildcare.map(el => {
                    return {
                        id: el.type,
                        modal: <TeachingDocumentation key={el.type} title={`Teaching Documentation - ${el.short_title}`} details={el} employmentSetting={'earlyChildcare'} />,
                        // state: fields.accreditation?.earlyChildcare?.[`${locationState}`]?.[`${el.type}`]?.state || FieldState.INCOMPLETE
                        state: getVerifiedAccreditationDoc(fields.accreditation?.earlyChildcare?.[`${setting.locationState}`], el.type)?.state || FieldState.INCOMPLETE
                    }
                }) : []
        ),
        // ...docs.map(el => {
        //     return { id: el.type, modal: <TeachingDocumentation key={el.type} title={`Teaching Documentation - ${el.short_title}`} details={el} employmentSetting={}/>, state: fields.accreditation?.[`${el.type}`] ? fields.accreditation?.[`${el.type}`].state || 'incomplete' : 'incomplete' }
        // }),

        // (!state || state.length === 0 || state.includes(vitState) ? 
        //     { id: REG_STEP.VIT, modal: <VitDocumentation/>, state: vitState }:null
        // ),
        // (!state || state.length === 0 || state.includes(mrState) ? 
        //     { id: REG_STEP.MANDATORY_REPORTING, modal: <MandatoryReporting/>, state: mrState }:null
        // ),
        { id: REG_STEP.REFERENCE, modal: <Reference />, state: referenceState },
        { id: REG_STEP.EMERGENCY_CONTACTS, modal: <EmergencyContact />, state: emergencyState },
        // ((!state || state.length === 0 || state.includes(accredLevelState)) && (((fields.setLocation?.homeState || fields.homeAddress?.homeState) === "NSW") && ![TeacherAccountState.APPROVED, TeacherAccountState.ONHOLD].includes(fields.accountState?.value as TeacherAccountState)) ?
        ((setting.locationState === "NSW" && docs.school !== null && ![TeacherAccountState.APPROVED, TeacherAccountState.ONHOLD].includes(fields.accountState?.value as TeacherAccountState)) ?
            { id: REG_STEP.ACCREDITATION_LEVEL, modal: <AccreditationLevel />, state: accredLevelState } : null
        ),


        // FIXME: Remove for DTB-454 Merge
        // // (!submitted && (!state || state.length === 0 || state.includes(bankState)) ?
            // { id: REG_STEP.BANK, modal: <BankDetails />, state: bankState } : null
        //     // ),
        { id: REG_STEP.BANK, modal: <BankDetails />, state: bankState },
        // // (!submitted && (!state || state.length === 0 || state.includes(superState)) ?
        // //     { id: REG_STEP.SUPER, modal: <SuperDetails />, state: superState } : null
        // // ),
        { id: REG_STEP.SUPER, modal: <SuperDetails />, state: superState },
        // // (!submitted && (!state || state.length === 0 || state.includes(taxState)) ?
        // //     { id: REG_STEP.TAX, modal: <TaxDetails />, state: taxState } : null
        // // ),
        { id: REG_STEP.TAX, modal: <TaxDetails />, state: taxState }
    ].filter(el => el !== null);

    return temp as { id: string, modal: JSX.Element, state: string }[];
}

export interface IRegModalWrapper {
    children: JSX.Element;
    title: string;
    handleUpdate: () => void | Promise<void>;
    handleUpdateRequest?: () => void | Promise<void>;
    state?: string;
    isAllComplete?: boolean;
    // expired?: boolean;
    disableBtns?: boolean;
}
export const RegModalWrapper = (props: IRegModalWrapper) => {
    const dispatch = useDispatch();
    const step = useSelector((state: RootState) => state.userReg.step);
    const stateList = useSelector((state: RootState) => state.userReg.stateList);
    const accountState = useSelector((state: RootState) => state.userReg.fields.accountState?.value);
    const fromEdit = useSelector((state: RootState) => state.userReg.fromEdit);
    const [fadeIn, setFadeIn] = useState(true);

    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px');

    const triggerFade = () => {
        setFadeIn(false);
        const timer = setTimeout(() => setFadeIn(true), 100);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        triggerFade();
    }, [step]);

    const handleNext = async () => {
        setLoading(true);
        await props.handleUpdate();
        setLoading(false);
        dispatch(setStep(step + 1));
    }

    const handleClose = async () => {
        setLoading(true);
        await props.handleUpdate();
        setLoading(false);
        dispatch(setOpenRegModal(false));
    }

    const handleUpdateRequest = async () => {
        setLoading(true);
        props.handleUpdateRequest && await props.handleUpdateRequest();
        setLoading(false);

        dispatch(setOpenRegModal(false));
    }

    const renderStyle = () => {
        switch(props.state as FieldState) {
            case FieldState.EXPIRED: case FieldState.REJECTED: return styles['topBar--rejected'];
            default: return styles.topBar;
        }
    }

    return (
        <>
            <Stack className={renderStyle()}>
                <IconButton onClick={() => dispatch(setOpenRegModal(false))} disabled={loading}><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M24 8L8 24M8 8L24 24" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                <Stack direction={'row'} gap={pxToRem(16)} alignItems={'center'}>
                    <h4 className={styles.h4}>{props.title}</h4>
                    <Zoom in={props.isAllComplete}>
                        <svg width={isMobile ? '24px' : '36px'} height={isMobile ? '24px' : '36px'} style={{ minWidth: isMobile ? '24px' : '36px', minHeight: isMobile ? '24px' : '36px' }} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="18" cy="18" r="16.5" stroke="white" strokeWidth="3" /> <path d="M25.2008 12.6001L15.3008 22.5001L10.8008 18.0001" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                    </Zoom>
                </Stack>
            </Stack>
            <Fade in={fadeIn}>
                <Container className={styles.scrollContainer} maxWidth={'md'} sx={{ visibility: fadeIn ? 'visible' : 'hidden' }}>
                    {
                        props.state === 'rejected' &&
                        <p className={styles.p} style={{ color: styles.secondaryPink400 }}>This item was rejected, please update your document</p>
                    }
                    {
                        props.state === 'expired' &&
                        <p className={styles.p} style={{ color: styles.secondaryPink400 }}>This item is expired, please update your document</p>
                    }
                    {props.children}
                </Container>
            </Fade>
            {
                fromEdit ?
                    <Stack className={styles.footerBar} alignItems={'flex-end'}>
                        <Divider />
                        {
                            (accountState === TeacherAccountState.APPROVED || accountState === TeacherAccountState.ONHOLD) ?
                                <PrimaryButton className={styles.saveBtn} sx={{ width: 'fit-content' }} onClick={props.handleUpdate} loading={loading} disabled={props.disableBtns}>Upload</PrimaryButton>
                                :
                                <PrimaryButton className={styles.saveBtn} sx={{ width: 'fit-content' }} onClick={handleClose} loading={loading} disabled={props.disableBtns && !props.isAllComplete}>Save & Close</PrimaryButton>
                        }
                    </Stack>
                    :
                    <Stack className={styles.footerBar}>
                        <Divider />
                        {
                            isMobile ?
                                <Stack>
                                    {
                                        !fromEdit ?
                                            <TeacherRegStepper />
                                            // <MobileStepper variant='dots' activeStep={step} steps={totalSteps} backButton={undefined} nextButton={undefined} sx={{position: 'relative', background:'transparent', marginTop: pxToRem(4),
                                            //     alignSelf:'center'
                                            // }}/>
                                            : null
                                    }
                                    <Stack direction={'row'} gap={pxToRem(10)}>
                                        <PrimaryOutlinedButton className={styles.backBtn} sx={{ display: step > 0 ? 'block' : 'none', minWidth: '0 !important', flex: 1 }} onClick={() => { props.handleUpdate(); dispatch(setStep(step - 1)); }} disabled={loading}>BACK</PrimaryOutlinedButton>
                                        <PrimaryButton className={styles.nextBtn} sx={{ minWidth: '0 !important', flex: 1 }} onClick={handleNext} loading={loading}>{props.isAllComplete ? 'NEXT' : 'SKIP'}</PrimaryButton>
                                    </Stack>
                                    {/* <SecondaryButton className={styles.saveBtn} onClick={handleClose}>Save & Close</SecondaryButton> */}
                                </Stack>
                                :
                                <>
                                    {
                                        !fromEdit ?
                                            <TeacherRegStepper />
                                            // <MobileStepper variant='dots' activeStep={step} steps={totalSteps} backButton={undefined} nextButton={undefined} sx={{background:'transparent', position:'relative',width: 'fit-content'}}/>
                                            : null
                                    }
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={pxToRem(12)}>
                                        <PrimaryOutlinedButton className={styles.backBtn} sx={{ visibility: step > 0 ? 'visible' : 'hidden' }} onClick={() => { props.handleUpdate(); dispatch(setStep(step - 1)); }} disabled={loading}>BACK</PrimaryOutlinedButton>
                                        <Stack direction='row' alignItems='center' flexWrap={'wrap-reverse'} gap={pxToRem(12)}>
                                            {/* <SecondaryButton className={styles.saveBtn} onClick={handleClose}>Save & Close</SecondaryButton> */}
                                            <PrimaryButton className={styles.nextBtn} onClick={handleNext} loading={loading}>{props.isAllComplete ? 'NEXT' : 'SKIP'}</PrimaryButton>
                                        </Stack>

                                    </Stack>
                                </>

                        }
                    </Stack>
            }

        </>
    );
};


export interface IEmployeeDocsModalWrapper {
    children: JSX.Element;
    title: string;
    handleUpdate: () => void | Promise<void>;
    state?: string;
    isAllComplete?: boolean;
    disableBtns?: boolean;
}
export const EmployeeDocsModalWrapper = (props: IEmployeeDocsModalWrapper) => {
    const dispatch = useDispatch();
    const step = useSelector((state: RootState) => state.userReg.step);
    const accountState = useSelector((state: RootState) => state.userReg.fields.accountState?.value);
    const [fadeIn, setFadeIn] = useState(true);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px');

    const triggerFade = () => {
        setFadeIn(false);
        const timer = setTimeout(() => setFadeIn(true), 100);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        triggerFade();
    }, [step]);

    // const handleClose = () => {
    //     props.handleUpdate();
    //     dispatch(setOpenRegModal(false));
    // }

    const handleClose = async () => {
        setLoading(true);
        await props.handleUpdate();
        setLoading(false);
        dispatch(setOpenRegModal(false));
    }

    const renderStyle = () => {
        switch (accountState) {
            case TeacherAccountState.REJECTED: case TeacherAccountState.ONHOLD:
                if (props.state === 'rejected' || props.state === 'onHold') {
                    return styles['topBar--rejected'];
                } else {
                    return styles.topBar;
                }
            default:
                return styles.topBar;
        }
    }

    return (
        <>
            <Stack className={renderStyle()}>
                <IconButton onClick={() => dispatch(setOpenRegModal(false))}><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M24 8L8 24M8 8L24 24" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                <Stack direction={'row'} gap={pxToRem(16)} alignItems={'center'}>
                    <h4 className={styles.h4}>{props.title}</h4>
                    <Zoom in={props.isAllComplete}>
                        <svg width={isMobile ? '24px' : '36px'} height={isMobile ? '24px' : '36px'} style={{ minWidth: isMobile ? '24px' : '36px', minHeight: isMobile ? '24px' : '36px' }} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="18" cy="18" r="16.5" stroke="white" strokeWidth="3" /> <path d="M25.2008 12.6001L15.3008 22.5001L10.8008 18.0001" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                    </Zoom>
                </Stack>
            </Stack>
            <Fade in={fadeIn}>
                <Container className={styles.scrollContainer} maxWidth={'md'} sx={{ visibility: fadeIn ? 'visible' : 'hidden' }}>
                    {
                        props.state === 'rejected' &&
                        <p className={styles.p} style={{ color: styles.secondaryPink400 }}>This item was rejected, please update your document</p>
                    }
                    {props.children}
                </Container>
            </Fade>
            <Stack className={styles.footerBar} alignItems={'flex-end'}>
                <Divider />
                <PrimaryButton className={styles.saveBtn} sx={{ width: 'fit-content' }} onClick={handleClose} disabled={props.disableBtns && !props.isAllComplete} loading={loading}>Save & Close</PrimaryButton>
            </Stack>
        </>
    );
}