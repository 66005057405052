import { WORKING_RIGHT_TYPES } from "../pages/Dashboard/RegistrationModals/components/helper";
import { TeacherFields } from "../redux/types";

export enum TeacherAccountState {
    INCOMPLETE = "incomplete",
    SUBMITTED = "submitted",
    APPROVED = "approved",
    REJECTED = "rejected",
    ONHOLD = "onHold",
    DELETED = "deleted"
}

export enum FieldState {
    APPROVED = "approved",
    SUBMITTED = "submitted",
    REJECTED = "rejected",
    EXPIRED = 'expired',
    INCOMPLETE = "incomplete",
}

export enum TeacherAvailabilityStateForDate {
    unavailable='unavailable',
    available='available',
    booked='booked',
}

export type TeacherAvailability = {
    [date: string]: string;
}

export enum TeacherPlacementState {
    confirmed='confirmed',
    enRoute='enRoute',
    nearby='nearby',
    inProgress='inProgress',
    earlyCheckout='earlyCheckout',
    completed='completed',
    cancelled='cancelled'
}

export enum ConfirmedPlacementState {
    confirmed='confirmed',
    cancelledBySchool='cancelledBySchool',
    cancelledByTeacher='cancelledByTeacher'
}

export type TeacherPlacement = {
    date?: string;
    notified?: number;
    schoolUid?: string;
}

export type TeacherPlacementKeys = {
    [year: string]: {
        [date: string]: {
            [placementKey: string]: TeacherPlacement;
        }
    }
}

export enum NSWAccreditationLevel {
    BAND1='band1',
    BAND2='band2',
    BAND3='band3',
    UNSURE=`I'm Unsure`
}

export type ECCAccreditationLevel = 'educator' | 'earlyChildhoodTrained';


export const ECCQualificationLevels = ['certThree', 'dimploma']

export const ECCQualToTitle = (qual?: string|null) => {
    switch (qual) {
        case 'certThree': return 'Certificate III';
        case 'dimploma': return 'Diploma';
        case 'earlyChildhoodTrained': return 'Early Childhood Trained';
        case 'other': return 'Other';
        case 'noFormalTraining': return 'No Formal Training';
        default: return '-'
    }
}

export enum TeacherRequestTypes {
    NAME='actualName',
    ACC_DOC_UPDATE='accreditationDocument',
    NEW_ACCREDITATION='newAccreditation',
    HOME_ADDRESS='homeAddress',
    WORKING_RIGHTS='workingRights',
    PROFILE_PICTURE='profilePicture',
}

export interface TeacherRequest {
    actionedTimeStamp?: number;
    state: string;
    timeStamp: number;
    teacherName: string;
    teacherUid: string;
    type: TeacherRequestTypes;
}

export enum NameChangeReason {
    LASTNAME='I changed my last name',
    FIRSTNAME='I changed my first name',
    BOTHNAMES='I changed both my names',
    INCORRECT='My name is incorrect'
}
export interface TeacherNameChangeRequest extends TeacherRequest {
    reason?: NameChangeReason;
    firstName: string;
    lastName: string;
    firstNameActual: string;
    url: string;
}

export interface TeacherPictureChangeRequest extends TeacherRequest {
    url: string|null;
}
export interface TeacherAccreditationChangeRequest extends TeacherRequest {
    documentUid?: string;
    url?: string|null;
}
export interface TeacherWorkingRightsChangeRequest extends TeacherRequest {
    workingRightsType: WORKING_RIGHT_TYPES;
    url?: string;
}
export interface TeacherLocationChangeRequest extends TeacherRequest {
    location: TeacherFields['homeAddress']
}