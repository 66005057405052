import { useDispatch, useSelector } from "react-redux";
import { RegModalWrapper } from "../ModalContent";
import { RootState } from "../../../../redux/store";
import pxToRem from "../../../../helpers/pxToRem";
import { Checkbox, Divider, Stack } from "@mui/material";
import styles from "../index.module.scss";
import { UserRegTextField } from "../../../../components/TextField";
import { getErrorMsgForEmailInput, getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import useTextInput from "../../../../hooks/useTextInput";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import { useEffect, useState } from "react";
import { FieldState } from "../../../../firebase/types-teacher";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { updateUserInfo } from "../../../../firebase/general-apis";

const Reference = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);

    const rFn = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Reference First Name") }, defaultValue: reg.reference?.firstName });
    const rLn = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Reference Last Name") }, defaultValue: reg.reference?.lastName });
    const rEmail = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForEmailInput(input) }, defaultValue: reg.reference?.email });

    const [selectedInterview, setSelectedInterview] = useState(reg.reference?.requestedInterview || false);

    const isAllComplete = () => {
        return (!rFn.hasError && rFn.value !== '' &&
            !rLn.hasError && rLn.value !== '' &&
            !rEmail.hasError && rEmail.value !== '') || selectedInterview === true
    }

    const isSomeComplete = () => {
        return (!rFn.hasError && rFn.value !== '' ||
            !rLn.hasError && rLn.value !== '' ||
            !rEmail.hasError && rEmail.value !== '')
    }

    const handleUpdate = async () => {
        try {
            if(!userId) throw new Error("User is not authenticated.")
        let updateData: UserUpdate = {};

        if (selectedInterview) {
            updateData = {
                teacher: {
                    path: '/reference',
                    data: {
                        requestedInterview: true,
                        state: 'submitted'
                    }
                }
            }
        } else if (reg.reference) {
            updateData = {
                teacher: {
                    path: "/reference",
                    data: {
                        ...(reg.reference.email ?
                            reg.reference.email !== rEmail.value ? { email: !rEmail.hasError && rEmail.hasTouched ? rEmail.value : null } : null
                            :
                            { email: !rEmail.hasError && rEmail.hasTouched ? rEmail.value : null }
                        ),
                        ...(reg.reference.firstName ?
                            reg.reference.firstName !== rFn.value ? { firstName: !rFn.hasError && rFn.hasTouched ? rFn.value : null } : null
                            :
                            { firstName: !rFn.hasError && rFn.hasTouched ? rFn.value : null }
                        ),
                        ...(reg.reference.lastName ?
                            reg.reference.lastName !== rLn.value ? { lastName: !rLn.hasError && rLn.hasTouched ? rLn.value : null } : null
                            :
                            { lastName: !rLn.hasError && rLn.hasTouched ? rLn.value : null }
                        ),
                        requestedInterview: null,
                        state: isAllComplete() ? FieldState.SUBMITTED : isSomeComplete() ? FieldState.INCOMPLETE : null
                    }
                }
            }
        } else {
            updateData = {
                teacher: {
                    path: "/reference",
                    data: {
                        email: !rEmail.hasError && rEmail.hasTouched ? rEmail.value : null,
                        firstName: !rFn.hasError && rFn.hasTouched ? rFn.value : null,
                        lastName: !rLn.hasError && rLn.hasTouched ? rLn.value : null,
                        requestedInterview: null,
                        state: isAllComplete() ? FieldState.SUBMITTED : isSomeComplete() ? FieldState.INCOMPLETE : null
                    }
                }
            }
        }
        await updateUserInfo(userId, updateData);
        dispatch(updateFields(updateData));
    } catch (error: any) {
        dispatch(setErrorMsg(error.message))
    }
    }

    useEffect(() => {
        reg.reference?.firstName ? rFn.setValue(reg.reference?.firstName) : rFn.reset();
        reg.reference?.lastName ? rLn.setValue(reg.reference?.lastName) : rLn.reset();
        reg.reference?.email ? rEmail.setValue(reg.reference?.email) : rEmail.reset();
        setSelectedInterview(reg.reference?.requestedInterview || false);
    }, [reg.reference]);


    return (
        <>
            <RegModalWrapper title="Reference" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    reference: reg.reference
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Reference</h5>
                        <p className={styles.p}>To work with Spark Relief Teachers you will need to provide a reference who is currently a registered teacher in Australia.</p>
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <UserRegTextField
                            value={rFn.value}
                            onChange={(e) => rFn.setValue(e.target.value)}
                            error={rFn.hasTouched && rFn.hasError} helperText={rFn.getErrorMessage()}
                            label="Reference First Name" disabled={selectedInterview}
                        />
                        <UserRegTextField
                            value={rLn.value}
                            onChange={(e) => rLn.setValue(e.target.value)}
                            error={rLn.hasTouched && rLn.hasError} helperText={rLn.getErrorMessage()}
                            label="Reference Last Name" disabled={selectedInterview}
                        />
                        <UserRegTextField
                            value={rEmail.value}
                            onChange={(e) => rEmail.setValue(e.target.value)}
                            error={rEmail.hasTouched && rEmail.hasError} helperText={rEmail.getErrorMessage()}
                            label="Reference Email" disabled={selectedInterview}
                        />
                    </Stack>
                    <Divider>Or</Divider>
                    <Stack direction={'row'} gap={pxToRem(4)} justifyContent={'center'} alignItems={'center'}>
                        <Checkbox checked={selectedInterview} onChange={() => setSelectedInterview(prev => !prev)} />
                        <small>Request online interview</small>
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default Reference;