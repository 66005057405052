import { InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { RegModalWrapper } from "../ModalContent";
import styles from "../index.module.scss";
import { useEffect, useMemo, useState } from "react";
import pxToRem from "../../../../helpers/pxToRem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { UserRegTextField } from "../../../../components/TextField";
import CountrySelect from "../../../../components/AutoComplete/CountryInput";
import useTextInput from "../../../../hooks/useTextInput";
import { getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { documentUploadHandler, primaryIdentity } from "./helper";
import { getIncompleteState } from "../../ReviewPage/helper";
import { CustomizedDatePicker } from "../../../../components/DatePicker";
import { TeacherRegFormControl } from "../../../../components/FormControl";
import { DateValidationError } from "@mui/x-date-pickers/models";
import FileUpload, { FileType } from "../../../../components/FileUpload";
import { setErrorMsg } from "../../../../redux/uiSlice";
import {v4 as uuidv4} from 'uuid';
import { RegFileUploadLabel } from "../../../../components/FileUpload/RegUploadComponents";
import { updateUserInfo } from "../../../../firebase/general-apis";

const PrimaryIdentity = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);
    const [selected, setSelected] = useState(reg.primaryIdentification?.documentType);
    const [dob, setDob] = useState<Dayjs | null>(reg.primaryIdentification?.birthDate ? dayjs(reg.primaryIdentification?.birthDate, "DD-MM-YYYY") : null);

    const [dateError, setDateError] = useState<DateValidationError | null>(null);
    const errorMessage = useMemo(() => {
        switch (dateError) {
            case 'maxDate': {
                return 'Please select a date before today'
            }
            case 'shouldDisableDate': {
                return 'This birthdate does not match the other identification document'
            }
        }
    }, [dateError])

    const nameOnDoc = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Name on document", null, null, undefined, true) }, defaultValue: reg.primaryIdentification?.name });
    const documentNumber = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Document Number", null, null, undefined, true) }, defaultValue: reg.primaryIdentification?.reference });
    const [country, setCountry] = useState(reg.primaryIdentification?.country);

    const [file, setFile] = useState<FileType | undefined>(reg.primaryIdentification?.value ? { file: reg.primaryIdentification?.value } : undefined);
    const onFileChange = (file: File) => {
        setFile({ file: file, fileName: file.name });
    }

    const isAllComplete = () => {
        return (selected !== undefined && country !== undefined && nameOnDoc.value.trim() !== "" && !nameOnDoc.hasError && !dateError && dob?.isValid() && documentNumber.value.trim() !== "" && !documentNumber.hasError && file !== undefined) || false
    }

    const isSomeComplete = () => {
        return selected !== undefined || country !== undefined || nameOnDoc.value !== "" || (!dateError && dob?.isValid()) || documentNumber.value !== "" || file !== undefined
    }

    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            let updateData: UserUpdate = {};
            let formattedDob = '';
            if (dob && !dateError && dob?.isValid()) { formattedDob = dob.format("DD-MM-YYYY"); }

            const { fileUrl, fileType, docTitle } = await documentUploadHandler(userId, file, { url: reg.primaryIdentification?.value, type: reg.primaryIdentification?.type, docTitle: reg.primaryIdentification?.docTitle }) // TODO: Add type and name here

            console.log(`fileUrl: ${fileUrl}`);
            console.log(`fileType: ${fileType}`);
            console.log(`docTitle: ${docTitle}`);

            if (reg.primaryIdentification && reg.primaryIdentification.id) {
                // Update data
                updateData = {
                    teacher: {
                        path: `/identity/${reg.primaryIdentification.id}`,
                        data: {
                            ...(reg.primaryIdentification.birthDate ?
                                reg.primaryIdentification.birthDate !== formattedDob ? { birthDate: formattedDob ? formattedDob : null } : null
                                :
                                { birthDate: formattedDob ? formattedDob : null }
                            ),
                            ...(reg.primaryIdentification.country ?
                                reg.primaryIdentification.country !== country ? { country: country ? country : null } : null
                                :
                                { country: country ? country : null }
                            ),
                            ...(reg.primaryIdentification.documentType ?
                                reg.primaryIdentification.documentType !== selected ? { documentType: selected ? selected : null } : null
                                :
                                { documentType: selected ? selected : null }
                            ),
                            ...(reg.primaryIdentification.name ?
                                reg.primaryIdentification.name !== nameOnDoc.value ? { name: !nameOnDoc.hasError ? nameOnDoc.value : null } : null
                                :
                                { name: !nameOnDoc.hasError && nameOnDoc.value !== "" ? nameOnDoc.value : null }
                            ),
                            ...(reg.primaryIdentification.reference ?
                                reg.primaryIdentification.reference !== documentNumber.value ? { reference: !documentNumber.hasError ? documentNumber.value : null } : null
                                :
                                { reference: !documentNumber.hasError && documentNumber.value !== "" ? documentNumber.value : null }
                            ),
                            value: fileUrl, type: fileType, docTitle: docTitle,
                            ...(isAllComplete() ? { state: 'submitted' } : { state: 'incomplete' })
                        }
                    }
                }
            } else {
                // Create new document
                updateData = {
                    teacher: {
                        path: `/identity`,
                        data: {
                            [`${uuidv4()}`]: {
                                birthDate: formattedDob ? formattedDob : null,
                                country: country ? country : null,
                                documentType: selected ? selected : null,
                                name: nameOnDoc.value ? nameOnDoc.value : null,
                                reference: documentNumber.value ? documentNumber.value : null,
                                value: fileUrl, type: fileType, docTitle: docTitle,
                                ...(isAllComplete() ? { state: 'submitted' } : isSomeComplete() ? { state: 'incomplete' } : null)
                            }
                        }
                    }
                }
            }

            await updateUserInfo(userId, updateData);

            // dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    useEffect(() => {
        reg.primaryIdentification?.documentType ? setSelected(reg.primaryIdentification.documentType) : setSelected(undefined);
    }, [reg.primaryIdentification?.documentType]);
    useEffect(() => {
        reg.primaryIdentification?.country ? setCountry(reg.primaryIdentification?.country) : setCountry(undefined);
    }, [reg.primaryIdentification?.country]);
    useEffect(() => {
        reg.primaryIdentification?.name ? nameOnDoc.setValue(reg.primaryIdentification?.name) : nameOnDoc.reset();
    }, [reg.primaryIdentification?.name]);
    useEffect(() => {
        reg.primaryIdentification?.birthDate ? setDob(dayjs(reg.primaryIdentification?.birthDate, "DD-MM-YYYY")) : setDob(null);
    }, [reg.primaryIdentification?.birthDate]);
    useEffect(() => {
        reg.primaryIdentification?.reference ? documentNumber.setValue(reg.primaryIdentification?.reference) : documentNumber.reset();
    }, [reg.primaryIdentification?.reference])

    useEffect(() => {
        reg.primaryIdentification?.value ? setFile({file: reg.primaryIdentification?.value, fileName: reg.primaryIdentification.docTitle}) : setFile(undefined)
    }, [reg.primaryIdentification?.value, reg.primaryIdentification?.docTitle])

    return (
        <>
            <RegModalWrapper title="Identity Verification - Primary Document" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    primaryIdentification: reg.primaryIdentification,
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <p className={styles.p}>To identify your identity and working rights you will need to upload two forms of identification</p>
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <TeacherRegFormControl variant="standard">
                            <InputLabel>Primary Identification</InputLabel>
                            <Select variant="standard" value={selected} onChange={(e) => setSelected(e.target.value)}>
                                {
                                    reg.workingRights?.value === "temporaryVisaHolder" ?
                                        <MenuItem value={primaryIdentity[0].value}>{primaryIdentity[0].label}</MenuItem>
                                        :
                                        [...primaryIdentity].filter(el => el.value !== reg.secondaryIdentification?.documentType).sort((a, b) => a.value.localeCompare(b.value)).map(el => (
                                            <MenuItem key={el.label} value={el.value}>{el.label}</MenuItem>
                                        ))
                                }
                            </Select>
                        </TeacherRegFormControl>
                        {
                            selected !== undefined &&
                            <>
                                <CountrySelect value={country} setValue={(el) => setCountry(el)} />
                                <UserRegTextField
                                    value={nameOnDoc.value}
                                    onChange={(e) => nameOnDoc.setValue(e.target.value)}
                                    error={nameOnDoc.hasTouched && nameOnDoc.hasError} helperText={nameOnDoc.getErrorMessage()}
                                    label="Name on Document"
                                />
                                <Stack direction={'row'} gap={pxToRem(24)}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CustomizedDatePicker value={dob} onChange={(e) => setDob(e)} maxDate={dayjs().add(-1, 'day')} label="Date of Birth" format="DD/MM/YYYY"
                                            onError={(newError) => setDateError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: errorMessage
                                                }
                                            }}
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                            shouldDisableDate={(date) => reg.secondaryIdentification?.birthDate ? dayjs(date).format("DD-MM-YYYY") !== reg.secondaryIdentification?.birthDate : false}
                                        />
                                    </LocalizationProvider>
                                    <UserRegTextField
                                        value={documentNumber.value}
                                        onChange={(e) => documentNumber.setValue(e.target.value)}
                                        error={documentNumber.hasTouched && documentNumber.hasError} helperText={documentNumber.getErrorMessage()}
                                        label="Document Number"
                                        sx={{ flexGrow: 1, }}
                                    />
                                </Stack>
                                <RegFileUploadLabel file={file} reset={() => setFile(undefined)}/>
                                <FileUpload file={file} onChangeCallback={onFileChange} extensions={'document'} iconColor={styles.primaryYellow500} />
                            </>
                        }
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    )
}

export default PrimaryIdentity;