import moment from "moment";
// import { FieldState, TeacherAccountState } from "../../firebase/types-teacher";
import { AccreditationDocument, AccreditationDocumentDetails, LocalTeacherEmploymentSetting, LocalTeacherEmploymentType, RegAccredDocs, TeacherFields, toAccreditationDocument } from "../../redux/types";
import { ECCAccreditationLevel, FieldState, TeacherAccountState } from "../../firebase/types-teacher";
// import { AccreditationDocument, AccreditationDocumentDetails, LocalTeacherEmploymentType, RegAccredDocs, TeacherFields, toAccreditationDocument } from "../../redux/types";
// import { getCombinedState } from "./ReviewPage/helper";
import { isDocExpired } from "../../helpers/utils";
import { getCombinedState } from "./ReviewPage/helper";


export const getAccreditationDocuments = (userAccreditation: { [name: string]: AccreditationDocument }, documents: AccreditationDocumentDetails[] | null, accountState: TeacherAccountState | undefined, expiryCallback?: (val: number) => void, isPostReg?: boolean) => {
    let expiryCount: string[] = [];
    let accreditation: RegAccredDocs = {};
    for (let [key, v] of Object.entries(userAccreditation)) {
        if (v) {
            let detail = documents?.find(el => el.type === v.accreditationType)
            if (detail) {
                // Instantiate tempdoc
                let tempDoc = {
                    ...toAccreditationDocument(v, key),
                    // ...(accountState === TeacherAccountState.SUBMITTED ? { state: FieldState.SUBMITTED } : null)
                };
                let isExpired = isDocExpired(tempDoc.expiryDate);

                tempDoc = {
                    ...tempDoc,
                    // Everything is expired state no matter the state?
                    // state: tempDoc.state === FieldState.APPROVED && isExpired ? FieldState.EXPIRED : tempDoc.state
                    state: isExpired ? FieldState.EXPIRED : tempDoc.state
                }
                tempDoc.state = stateConversion(tempDoc.state as FieldState, accountState);
                
                accreditation[`${key}`] = tempDoc

                // if (isPostReg && tempDoc.state === FieldState.SUBMITTED) submittedDocs[`${key}`] = tempDoc;

                // // if(!isPostReg || (isPostReg && ![FieldState.SUBMITTED, FieldState.INCOMPLETE].includes(tempDoc.state as FieldState))) {
                // if(!isPostReg || (isPostReg && ![FieldState.INCOMPLETE].includes(tempDoc.state as FieldState))) {

                //     // Check if the document exists
                //     if (Object.keys(accreditation).includes(v.accreditationType || '')) {
                //         let updateexpiry = false;


                //         // Take more recent document
                //         if (tempDoc.expiryDate && accreditation[`${v.accreditationType}`].expiryDate) {
                //             if (moment(tempDoc.expiryDate, "DD-MM-YYYY").isAfter(moment(accreditation[`${v.accreditationType}`].expiryDate, "DD-MM-YYYY"))) {
                //                 accreditation[`${v.accreditationType}`] = tempDoc;
                //                 updateexpiry = true;
                //             }
                //         }


                //         // if (moment(tempDoc.dateSubmitted, "DD-MM-YYYY").isAfter(moment(accreditation[`${v.accreditationType}`].dateSubmitted, "DD-MM-YYYY"))) {
                //         //     accreditation[`${v.accreditationType}`] = tempDoc;
                //         //     updateexpiry = true;
                //         // }
                //         // else if (moment(tempDoc.dateSubmitted, "DD-MM-YYYY").isSame(moment(accreditation[`${v.accreditationType}`].dateSubmitted, "DD-MM-YYYY"))) {
                //         //     if (tempDoc.expiryDate && accreditation[`${v.accreditationType}`].expiryDate) {
                //         //         if (moment(tempDoc.expiryDate, "DD-MM-YYYY").isAfter(moment(accreditation[`${v.accreditationType}`].expiryDate, "DD-MM-YYYY"))) {
                //         //             accreditation[`${v.accreditationType}`] = tempDoc;
                //         //             updateexpiry = true;
                //         //         }
                //         //     }
                //         // }

                //         if (updateexpiry && !detail.optional) {
                //             if (isExpired && !expiryCount.includes(v.accreditationType as string)) { expiryCount.push(v.accreditationType as string); }
                //             else { expiryCount = expiryCount.filter(el => el !== v.accreditationType as string); }
                //         }

                //     } else {
                //         accreditation[`${v.accreditationType}`] = tempDoc
                //         if (isExpired && !detail.optional) { expiryCount.push(v.accreditationType as string); }
                //     }
                // }
            }
        }
    }
    // Remove state calculation in listener callback
    accreditation.state = documents === null || documents.length === 0 ?
        FieldState.INCOMPLETE
        :
        // Set to submitted (complete) state if there are no required accreditation documents for the state
        // Only include required documents into the state derivation
         getCombinedState(documents.filter(el => !el.optional).map(el => {
            const doc = getVerifiedAccreditationDoc(accreditation, el.type);
            return doc?.state || FieldState.INCOMPLETE
        }))


    expiryCallback && expiryCallback(expiryCount.length);
    return accreditation;
}

export const accreditationSort = (a: AccreditationDocument, b: AccreditationDocument) => {
    const fieldStateOrder = Object.values(FieldState);
    return fieldStateOrder.indexOf((a.state || FieldState.INCOMPLETE) as FieldState) - fieldStateOrder.indexOf((b.state || FieldState.INCOMPLETE) as FieldState) ||
                moment(a.expiryDate).unix() - moment(b.expiryDate).unix()
}

export const getVerifiedAccreditationDoc = (accreditation: RegAccredDocs|null|undefined, accredType?: string, exclude?: FieldState[]): AccreditationDocument|undefined => {
    if(!accreditation) return undefined;
    let doc = (Object.values(accreditation)
        .filter(el => typeof el !== 'string' && el.accreditationType === accredType) as AccreditationDocument[])
        .sort(accreditationSort)

    return doc.length > 0 ? doc[0] : undefined;
}

export const whichEmployentSetting = (locationState: string, setting: TeacherFields['employmentSetting']): {locationState: string, type: LocalTeacherEmploymentType, level?:ECCAccreditationLevel} => {
    if (!setting) { return {locationState, type:'school'} };

    let isSchool: boolean = false;
    let isEducator: boolean = false;
    let level: ECCAccreditationLevel|undefined = undefined;

    for(let s of Object.values(setting || {}).filter(el => el.locationState === locationState)) {
        if(s.type === 'school') isSchool = true;
        if(s.type === 'earlyChildcare') {
            isEducator = true;
            level = s.level;
        }
    }

    if(isSchool && isEducator) {
        return {locationState, type: 'both', level}
    } else if (isEducator) {
        return {locationState, type: 'earlyChildcare', level}
    } 
    return {locationState, type: 'school'}

    // const types = Object.values(setting || {}).filter(el => el.locationState === locationState);
    // if (types.includes('school')) {
    //     if (types.includes('earlyChildcare')) {
    //         //both
    //         return {type: 'both', level: };
    //     }
    //     // only school
    //     return 'school';
    // } else if (types.includes('earlyChildcare')) {
    //     // only ecc
    //     return 'earlyChildcare';
    // }
    // return 'school';
}

export const stateConversion = (state: FieldState, accountState: TeacherAccountState | undefined): FieldState => {
    switch (accountState) {
        case TeacherAccountState.SUBMITTED: case TeacherAccountState.REJECTED: {
            return state === FieldState.APPROVED ? FieldState.SUBMITTED : (state || FieldState.INCOMPLETE);
        }
        default: {
            return state || FieldState.INCOMPLETE;
        }
    }
}

export const postStateConversion = (state: FieldState, empSettingState: TeacherAccountState | undefined) => {
    switch (empSettingState) {
        case TeacherAccountState.SUBMITTED: case TeacherAccountState.REJECTED: {
            return state === FieldState.APPROVED ? FieldState.SUBMITTED : (state || FieldState.INCOMPLETE);
        }
        case TeacherAccountState.APPROVED: case TeacherAccountState.ONHOLD: {

            break;
        }
        case TeacherAccountState.INCOMPLETE: default: {

            break;
        }
    }
}