import styled from "@emotion/styled";
import { Button } from "@mui/material";
import styles from "./index.module.scss";
import pxToRem from "../../helpers/pxToRem";
import LoadingButton from "@mui/lab/LoadingButton";

export const PrimaryButton = styled(LoadingButton)({
    height: 'fit-content',
    color: styles.shades0,
    textAlign: 'center',
    lineHeight: pxToRem(20),
    minWidth: pxToRem(164),
    backgroundColor: styles.primaryBlue500,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: pxToRem(40),
    '&:hover': {
        backgroundColor: styles.secondaryBlue300,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})
export const DestructiveButton = styled(LoadingButton)({
    height: 'fit-content',
    color: styles.shades0,
    textAlign: 'center',
    lineHeight: pxToRem(20),
    minWidth: pxToRem(164),
    backgroundColor: styles.destructiveRed400,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: pxToRem(40),
    '&:hover': {
        backgroundColor: styles.destructiveRed500,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})

export const SecondaryButton = styled(LoadingButton) ({
    height: 'fit-content',
    color: styles.primaryBlue500,
    fontSize: pxToRem(14),
    borderRadius: pxToRem(40),
    padding: `${pxToRem(14)} ${pxToRem(16)}`,
    '&:hover': {
        color: styles.primaryBlue600,
    }
})

export const PrimaryOutlinedButton = styled(LoadingButton) ({
    height: 'fit-content',
    color: styles.primaryBlue500,
    lineHeight: pxToRem(20),
    borderRadius: pxToRem(40),
    border: `1px solid ${styles.primaryBlue500}`,
    minWidth: pxToRem(164),
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    '&:hover': {
        color: styles.secondaryBlue300,
        borderColor: styles.secondaryBlue300,
        background:'inherit'
    },
    '&:disabled': {
        color: styles.neutrals500,
        borderColor: styles.neutrals300,
    },

})

export const AccountButton = styled(LoadingButton) ({
    height: 'fit-content',
    color: styles.shades0,
    lineHeight: pxToRem(20),
    borderRadius: pxToRem(40),
    backgroundColor: styles.primaryPurple500,
    minWidth: pxToRem(164),
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    '&:hover': {
        backgroundColor: styles.primaryPurple400,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})

export const SchoolMainButton = styled(LoadingButton) ({
    height: 'fit-content',
    color: styles.shades0,
    // lineHeight: '100%',
    borderRadius: pxToRem(40),
    backgroundColor: styles.primaryBlue500,
    minWidth: 'fit-content',
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    '&:hover': {
        backgroundColor: styles.secondaryBlue300,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})

export const DangerButton = styled(LoadingButton)({
    height: 'fit-content',
    color: styles.shades0,
    textAlign: 'center',
    lineHeight: pxToRem(20),
    minWidth: pxToRem(164),
    backgroundColor: '#F5857F',
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: pxToRem(40),
    '&:hover': {
        backgroundColor: '#DB7772',
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})

export const ApproveButton = styled(LoadingButton)({
    height: 'fit-content',
    color: styles.shades0,
    textAlign: 'center',
    lineHeight: pxToRem(20),
    minWidth: pxToRem(164),
    backgroundColor: styles.primaryGreen500,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: pxToRem(40),
    '&:hover': {
        backgroundColor: styles.primaryGreen400,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})

export const RefereeAgreeButton = styled(LoadingButton)({
    height: 'fit-content',
    color: styles.shades0,
    textAlign: 'center',
    lineHeight: pxToRem(24),
    minWidth: pxToRem(164),
    backgroundColor: styles.primaryPurple500,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: pxToRem(40),
    fontSize: pxToRem(16),


    '&:hover': {
        backgroundColor: styles.primaryPurple400,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})

export const RefereeDisagreeButton = styled(LoadingButton)({
    height: 'fit-content',
    color: styles.shades0,
    textAlign: 'center',
    lineHeight: pxToRem(24),
    minWidth: pxToRem(164),
    backgroundColor: styles.primaryYellow500,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    borderRadius: pxToRem(40),
    fontSize: pxToRem(16),


    '&:hover': {
        backgroundColor: styles.primaryYellow400,
    },
    '&:disabled': {
        backgroundColor: styles.neutrals100,
        color: styles.neutrals500
    }
})


export const AccountPageButton = styled(PrimaryButton)({
    backgroundColor: styles.secondaryBlue900,
    width: 'fit-content',

    '&:hover': {
        backgroundColor: styles.secondaryBlue800,
    }
})