import { PlaceType } from "../../../components/AutoComplete";

const defaultErrorMsg = "Please enter a valid address."
export const isValidPlaceType = (place: PlaceType|null): string|null => {
    if(!place) return defaultErrorMsg;
    let address = place.address_components;
    let geometry = place.geometry;

    if(!address || !geometry) return defaultErrorMsg;
    
    let countryObj = address.filter(el => el.types.includes('country'))[0];
    let stateObj = address.filter(el => el.types.includes('administrative_area_level_1'))[0];
    let suburbObj = address.filter(el => el.types.includes('locality'))[0];
    let postal_codeObj = address.filter(el => el.types.includes('postal_code'))[0];

    if(!countryObj || !countryObj.long_name) return defaultErrorMsg;
    if(countryObj.long_name !== 'Australia') return "We currently do not operate in this state."
    if(!stateObj || !stateObj.short_name) return defaultErrorMsg;
    if(!suburbObj || !suburbObj.long_name) return defaultErrorMsg;
    if(!postal_codeObj || !postal_codeObj.long_name) return defaultErrorMsg;

    return null;
}